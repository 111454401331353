import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight, TextFieldType } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownMenu from "../../../core/components/DropDownMenu";
import CompanyInfo from "../components/companyInfo";
import ThreeCheckComponent from "../components/threeCheckComponent";
import { applicationDocument, stageOneInitialValue } from "../constants";
import { Formik } from "formik";
import { stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import TextInputTextField from "../../../core/components/Input/TextInputTextField";
import { STAGEONECHECKLISTB } from "../../../router/config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  getMessage,
  sendMessage,
} from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";

const style = {
  position: "absolute" as "absolute",
  top: "10%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  mt: 50,
  overflow: "auto",
  height: 300,
};

const StageOneCheckList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const { data } = location.state;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [auditMode, setAuditMode] = useState("");
  const [chat, setChat] = useState<any>([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(sendMessage);
  const { execute: exeMessage, loading: getMesageLoading } =
    useAsyncRequest(getMessage);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const payload = {
        applicationId: applicationId,
      };
      exeMessage(payload, getMessageResponse);
    }
  };

  const getMessageResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setChat(data);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    exeMessage(payload, getMessageResponse);
  }, []);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || getMesageLoading} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{}}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Communication
            </Typography>
            <Box p={1}>
              {chat?.map((message: any) => {
                return (
                  <Box pt={2}>
                    <Typography fontWeight={FontWeight.BOLD}>
                      {message.sendersName} :{" "}
                    </Typography>
                    <Typography>{message.message}</Typography>
                  </Box>
                );
              })}
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              pt={3}
            >
              <TextInputTextField
                label="Enter Message"
                onChange={(val: any) => {
                  console.log(val);
                  setMessage(val.target.value);
                }}
                labelHeight={0}
                multiline
                width={400}
                row={3}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const { userEmail, applicationId } = data;
                  const { email, firstName, lastName } = user;
                  const payload = {
                    applicationId: applicationId,
                    auditorId: email,
                    sendersName: firstName + " " + lastName,
                    reciversId: userEmail,
                    message: message,
                  };
                  if (message) {
                    execute(payload, handleResponse);
                  }
                }}
                sx={{ mt: 10 }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          width={"100%"}
        >
          <Button
            sx={{ alignSelf: "flex-end" }}
            onClick={() => {
              handleOpen();
            }}
          >
            Communication
          </Button>
        </Box>
        <Typography variant="h6" fontWeight={FontWeight.BOLD} pl={2}>
          Stage 1 Audit Checklist
        </Typography>
        <CompanyInfo data={data} />
        <Formik
          initialValues={stageOneInitialValue}
          validationSchema={stageOneAuditCheckListA}
          onSubmit={(value) => {
            navigate(STAGEONECHECKLISTB, {
              state: { data: data, checkList: value },
            });
          }}
        >
          {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box pl={2}>
                <DropdownMenu
                  label={""}
                  name={"auditMode"}
                  value={auditMode}
                  menuLabel={"Select Mode"}
                  handleChange={(val: any) => {
                    setAuditMode(val.target.value)
                    setFieldValue(val.target.name, val.target.value);
                  }}
                  width={isMobile ? "100%" : 300}
                  type="LIST"
                  option={["On Site", "Off Site"]}
                />
              </Box>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                A. Application Documents
              </Typography>
              <ThreeCheckComponent
                rows={applicationDocument}
                setFieldValue={setFieldValue}
                fieldName={"applicationDocument"}
                heading={"A. Application Documents"}
                data={data}
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Box width={isMobile ? "100%" : "30%"}>
                  <TextInputTextField
                    mt={isMobile ? 3 : 1}
                    type={TextFieldType.TEXT}
                    name="applicationDocumentsCheckListRemarks"
                    label="Remarks"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    width={isMobile ? "100%" : 200}
                  />
                </Box>
                <Box width={"100%"}>
                  <TextInputTextField
                    mt={isMobile ? 5 : 1}
                    type={TextFieldType.TEXT}
                    name="applicationDocumentsCheckListFollowUpComments"
                    label="Follow-up / Feedback on Application Review Process (conducted before Stage 1):"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    width={"100%"}
                  />
                </Box>
              </Box>
              <Box
                pt={7}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Continue
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default StageOneCheckList;
