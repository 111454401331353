import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import { useNavigate } from "react-router-dom";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import AdminLayout from "../../dashboard/components/AdminLayout";
import Loader from "../../../core/components/Loader";
import {
  getNotifications,
  readNotification,
} from "../../../store/Auth/authService";
import { FontWeight } from "../../../core/types";
import moment from "moment";

const AuditorNotifications = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);
  const [notifcaition, setNotification] = useState([]);
  const [notifcaitionCount, setNotificationCount] = useState(0);

  const { execute, loading } = useAsyncRequest(getNotifications);
  const { execute: read, loading: readLoading } =
    useAsyncRequest(readNotification);

  const onSuccess = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setNotification(data.notifications);
      setNotificationCount(data.unRead)
    }
  };

  const getNot = () => {
    const payload = {
      userEmail: user.email,
    };
    execute(payload, onSuccess);
  };

  useEffect(() => {
    getNot();
  }, [navigate]);

  const onSuccessRead = (res: any) => {
    const { status } = res;
    if (status === 200) {
      getNot();
    }
  };

  const readOneNotification = (id: any) => {
    const payload = {
      id: id,
    };
    read(payload, onSuccessRead);
  };

  return (
    <AdminLayout>
      {loading || (readLoading && <Loader />)}
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography mb={2} mt={3} variant="h5">
          Notifcaitions
        </Typography>
        <Badge badgeContent={notifcaitionCount} color="primary">
        <CircleNotificationsRoundedIcon  color="action" fontSize="large" />
        </Badge>
      </Box>
      {notifcaition.length != 0 ? (
        <Box>
          {notifcaition.map((item: any) => {
            return (
              <Box
                mb={2}
                p={2}
                sx={{
                  backgroundColor: item.read ? "Background" : "ButtonShadow",
                  borderRadius: 3,
                  boxShadow: 5,
                }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <Typography mb={1} fontWeight={FontWeight.MEDIUM}>
                      {item.title}
                    </Typography>
                    <Typography>{item.message}</Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    <Typography pr={1}>
                      {moment(item.createdAt).format("DD MMM YYYY")}
                    </Typography>
                    {item.read ? (
                      <></>
                    ) : (
                      <Button
                        onClick={() => {
                          readOneNotification(item._id);
                        }}
                      >
                        Mark as Read
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          height={400}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h5">No notifcaition yet</Typography>
        </Box>
      )}
    </AdminLayout>
  );
};

export default AuditorNotifications;
