import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import DashboardContainer from "../../../core/components/DashboardContainer";
import AdminLayout from "../components/AdminLayout";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import { createData } from "../../../core/utils";
import { decisionDashboard } from "../../../store/Dashboard/dashboardService";
import OrderTable from "../AuditorDashboard/components/Recent";
import {
  AccountBalanceWallet as WalletIcon,
  CheckCircle as CheckCircleIcon,
  PendingActions as PendingIcon,
  AssignmentTurnedIn as CompletedIcon,
  HourglassBottom as InProgressIcon,
  Drafts as DraftsIcon,
  Receipt as QuoteIcon,
  Payments as PaymentsIcon,
  EventAvailable as UpcomingIcon
} from "@mui/icons-material";

const DecisionDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const [responseData, setResponseData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const {
    execute,
    loading: loading,
    response,
  } = useAsyncRequest(decisionDashboard);

  const handleRes = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      const { recentList } = data;
      let list: any = [];
      setResponseData(recentList);
      recentList.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    execute("", handleRes);
  }, []);

  const card = (title: string, subTitle: string, borderColor: string, Icon: JSX.Element, iconColor: string) => {
    return (
      <Box
        sx={{
          height: 100,
          width: isMobile ? "100%" : 250,
          letterSpacing: '0.5px',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 2,
          backgroundColor: "white",
          borderRadius: '26px',
          color: "#72777a",
          mx: 1,
          border: `1px solid ${iconColor}`,
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
            "& .icon": {
              transform: "translateX(5px)",
            },
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: 'space-between' }}>
          <Typography variant="body2" fontWeight={600}>
            {title}
          </Typography>
          {Icon}
        </Box>
        <Typography fontWeight={700}>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <AdminLayout>
      {
        !isMobile ? (
          <>
          <DashboardContainer>
            <Loader open={loading} />
            <Typography variant="h6" mb={3}>Status</Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
                mb: 4,
                mt: 2,
              }}
            >
              {card("Total Application", response?.data?.total, "#111111", <WalletIcon style={{ color: "#3f51b5" }} />, "#3f51b5")}
              {card("Completed Audits", response?.data?.completed, "#fff", <CompletedIcon style={{ color: "#ff9800" }} />, "#ff9800")}
              {card("Draft Created", response?.data?.darfts, "#fff", <UpcomingIcon style={{ color: "#00bcd4" }} />, "#00bcd4")}
              {card("Decision Pending", response?.data?.decisionPending, "#fff", <InProgressIcon style={{ color: "#f44336" }} />, "#f44336")}
            </Box>
            </DashboardContainer>
              <DashboardContainer>
            <Grid item xs={12} md={7} lg={8} p={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                <Typography mt={2} mb={4} variant="h6">
                  Recent Applications
                </Typography>
                </Grid>
                <Grid item />
              </Grid>
              <OrderTable data={data} resData={responseData} role={role} />
            </Grid>
          </DashboardContainer>
          </>
        ) : (
          <>
            <Loader open={loading} />
            <Typography variant="h6">Status</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
                mt: 2,
              }}
            >
              {card("Total Application", response?.data?.total, "#111111", <WalletIcon style={{ color: "#3f51b5" }} />, "#3f51b5")}
              {card("Completed Audits", response?.data?.completed, "#fff", <CompletedIcon style={{ color: "#ff9800" }} />, "#ff9800")}
              {card("Draft Created", response?.data?.darfts, "#fff", <UpcomingIcon style={{ color: "#00bcd4" }} />, "#00bcd4")}
              {card("Decision Pending", response?.data?.decisionPending, "#fff", <InProgressIcon style={{ color: "#f44336" }} />, "#f44336")}
            </Box>

            <Grid item xs={12} md={7} lg={8} mt={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                <Typography mt={4} mb={2} variant="h6">
                  Recent Applications
                </Typography>
                </Grid>
                <Grid item />
              </Grid>
              <OrderTable data={data} resData={responseData} role={role} />
            </Grid>
          </>
        )
      }

    </AdminLayout>
  );
};

export default DecisionDashboard;
