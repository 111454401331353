//auth
export const ROOT = "/";
export const SIGNIN = "/signIn";
export const SIGNUP = "/signUp";
export const FORGOTPASSWORD = "/forgotPassword";
export const OTPSCREEN = "/emailotp";
export const RESETPASSWORD = "/resetPassword";

//onboarding
export const CERTIFICATION = "/cerification";
export const COMPANYDETAILS = "/companydetails";
export const QUOTATION = "/quotation";
export const REVIEW = "/review";
export const TERMSCONDITIONS = "/termsandconditions";

//admin
export const DASHBOARD = "/dashboard";
export const AUDITORDASHBOARD = "/auditordashboard";
export const APPLICATIONDETAIL = "/applicationdetail";
export const CREATEQUOTATION = "/createquotation";
export const ADMINREVIEW = "/adminreview";
export const REVIEWSTATUS = "/reviewstatus";
export const AUDITORLIST = "/auditorlist";
export const APPLICATIONLIST = "/applicationlist";
export const APPLICATIONSTATUS = "/applicationstatus";
export const OPENCLOSEMEETINGINSTRUSTIONS = "/meetinginstructions";
export const DECISIONAPPLICATIONVIEW = "/applicationdetails";
export const REQUIREDLIST = "/requiredlist";
export const ALLAPPLICATIONS = "/allapplications";
export const ALLCERTIFICATIONS = "/allcertifications";
export const CHECKLIST = "/checklist";
export const ALLCOMPLAINTSLIST = "/allcomplaints";
export const COMPLAINTSEMAILLIST = "/complaintsmails";

//applications
export const RENEWALAPPLICATIONS = "/renewal";

//users
export const ADMINLIST = "/adminlist";
export const DECISIONLIST = "/decisionlist";
export const USERLIST = "/client";
export const USERUPDATE = "/userupdate";

//auditor
export const UPCOMMINGAUDITS = "/upcommoingaudits";
export const AUDITREPORT = "/auditreport";
export const AUDITDRAFT = "/auditdraft";
export const AUDITCHECKLIST = "/auditchecklist";
export const AUDITCHECKLISTVIEW = "/auditchecklistview";
export const AUDITFINDIGS = "/auditfindings";
export const STAGEONECHECKLIST = "/stageonechecklist";
export const STAGEONECHECKLISTB = "/stageonechecklistb";
export const REQUIREDCHECKLIST = "/requiredchecklist";
export const ASSESSMENTFINDINGS = "/assessmentfindings";
export const AUDITPLANING = "/auditplaning";
export const AUDITORAPPLICATIONINPROGRESS = "/auditorapplicationinprogress";
export const AUDITORNOTIFICATION = "/notifications";

export const AUDITCOMPLETED = "/auditcompleted";

//decision
export const DECISIONDASHBOARD = "/decisiondashboard";
export const DRAFTCREATEDAPPLICATION = "/draftcreated";
export const ISSUECERTIFICATE = "/issuecertificate";

//commomn
export const PROFILE = "/profle";

//user
export const USERDASHBOARD = "/userdashboard";
export const AUDITPLAN = "/auditschedule";
export const COMUNNICATIONS = "/communications";
export const FEEDBACKS = "/feedbacks";
export const APPLICATIONSUMMARY = "/applicationsummary";
export const REVIEWSUMMARY = "/reviewsummary";
export const PAYMENTS = "/payments";
export const VIEWCERT = "/viewCert";
export const VIEWAUDITFINDINGS = "/viewauditfindings";
export const USERCERTIFICATES = "/usercertificates";
export const COMPLAINTS = "/complaints";
