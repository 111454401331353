//auth
export const LOGIN = "auth/login";
export const GOOGLELOGIN = "auth/googlelogin";
export const REGISTRATION = "auth/register";
export const VERIFYEMAIL = "auth/verifyemail";
export const RESETPASSWORD = "auth/resetpassword";
export const DELETEUSER = "auth/deleteuser";
export const CHANGEPASSWORD = "auth/changepassword";
export const VERIFYFORGETPASSWORDEMAIL = "auth/verifyforgetpasswordemail";
export const VERIFYOTP = "auth/verifyotp";
export const RESETPASSWORDBYUSER = "auth/resetpasswordbyuser";

//onboarding
export const CERTIFICATIONLIST = "certification/getcertificationlist";
export const CREATEAPPLICATION = "application/application";
export const GETAPPLICATIONDETAILSBYMAIL = "application/getapplicationbyemail";
export const CREATESURVEILLLACE = "application/surveilance";
export const GETSURVEILLLACE = "application/getsurveilancebyuser";
export const ADDCOMPANYDETAIL = "companydetail/addcompanydetail";
export const GETQUOTATION = "quotation/getquotationbyapplication";
export const ACCEPTQUOTATION = "quotation/acceptquotation";
export const REQUESTCHANGEINQUOTATION = "quotation/requestchange";
export const PAYMENTGATEWAY = "application/makepayment";
export const CREATEORDER = "payments/createorder";
export const MAKEPAYMENT = "payments/makepayment";
export const AGREEMENT = "application/agreement";
export const ACTIVEAPPLICATIONS = "application/activeapplication";
export const ACTIVEAPPLICATIONSAUDITOR = "application/getapplicationbyauditor";
export const CREATEQUOTATION = "quotation/addquotation";
export const GETADMINISTRATORLIST = "auth/administratorlist";
export const GETAUDITORLIST = "auth/auditorlist";
export const APPLICATIONREVIEW = "application/applicationreview";
export const AUDITPLANING = "application/auditplaning";
export const GETAPPLICATIONREVIEWBYCOMPANYID =
  "application/getapplicationreviewbycompanyid";
export const STANGEONEAUDITCHECKLIST = "application/auditchecklist";
export const REQUIREDCHECKLIST = "application/requiredchecklist";
export const STAGECHECKLIST = "application/getauditchecklistbyapplication";
export const GETREQUIREDLIST = "application/getrequiredchecklist";
export const ASSESSMENTFINDINGS = "application/auditassessment";
export const GETASSESSMENTFINDINGS =
  "application/getauditassessmentbyapplicationid";
export const OPENCLOSEMEETINGCHECK = "application/openclosemeetingcheck";
export const FINDINGSAPPROVAL = "application/findingsapproval";
export const FINDINGSREJECT = "application/findingsreject";
export const GETAUDITPLANING = "application/getauditplainingbyapplicationid";
export const AUDITCHECKLISTFILL = "application/auditchecklistfill";

//communications

export const SENDMESSAGE = "communication/sendMessage";
export const GETMESSAGES = "communication/getMessages";
export const GETNOTIFICATION = "communication/getnotification";
export const READNOTIFICATION = "communication/readnotification";

export const APPLICATIONBYID = "application/getapplicationbyid";

//user
export const FINDINGSUPDATE = "application/findingsupdate";
export const REJECTAUDITOR = "application/rejectauditor";
export const ACCEPTAUDITOR = "application/acceptauditor";
export const GETCERTIFICATEBYUSER = "application/getcertifcatebyuser";
export const GETAPPLICATIONBYUSER = "application/getapplicationbyuseremail";
export const GETCERTIFICATESBYUSER = "application/getcertifcatebyuser";
export const DRAFTACCEPTBYUSER = "application/draftacceptbyuser";
export const DRAFTREJECTBYUSER = "application/draftrejectbyuser";
export const FEEDBACK = "feedback/feedback";
export const ADDCOMPLAINTS = "feedback/addcomplaints";
export const GETCOMPLAINTSBYUSER = "feedback/getcomplaintsbyuser"

//Auditor
export const AUDITORDASHBOARD = "application/auditdashboard";
export const AUDITORFINDINGSUPDATE = "application/audiorfindingsupdate";
export const GETAUDITORCHECKLIST = "certification/getauditorchecklist";

//decision
export const CREATEDRAFT = "application/createdraft";
export const UPDATEDRAFT = "application/updatedraft";
export const ISSUECERTIFICATE = "application/issuecertificate";
export const DECISIONDASHBOARD = "dashboard/decisiondashboard";
export const GETCERTIFICATEBYAPPLICATION = "application/getcertifcatebyapplication";
export const DECISIONACCEPT = "application/decsionaccept";
export const DECISIONREJECT = "application/decsionReject";

//admin
export const ADMINDASHBOARD = "dashboard/admindashboard?startDate=**sDate**&endDate=**eDate**";
export const ADDCERTIFICATION = "certification/addcertificationlist";
export const DELETECERTIFICATION = "certification/deletecertificationlist";
export const UPDATECERTIFICATION = "certification/updatecertificationlist";
export const ALLCERTIFICATION = "certification/getallcertificationlist";
export const ADDAUDITORCHECKLIST = "certification/addauditorchecklist";
export const GETALLAUDITORCHECKLIST = "certification/getallauditorchecklist";
export const UPDATEAUDITORCHECKLIST = "certification/updateauditorchecklist";
export const DELETEAUDITORCHECKLIST = "certification/deleteauditorchecklist";
export const UPDATEAUDITOR = "application/updateauditor";
export const GETFEEDBACKLIST = "feedback/getfeedbacklist";
export const GETALLCOMPLAINTS = "feedback/getallcomplaints"
export const REGISTERCOMPLAINTSEMAILS = "auth/registercomplaintsemail"
export const GETALLCOMPLAINTSEMAILS = "auth/getcomplaintsemaillist"
export const DELETECOMPLAINTSEMAILS = "auth/deletecomplaintsemaillist"